<template>
  <div class="serviceDetail">
    <!-- 背景图 -->
    <div class="content">
      <div class="content-left">
        <div class="content-left-list">
          <div class="content-left-list-title">
            质保无忧
            <img class="flag" src="" alt="" />
          </div>
          <div
            class="content-left-list-text"
            :class="titleActive == 1 ? 'active' : ''"
            @click="chooseActive(1)"
          >
            质量承诺
          </div>
          <div
            class="content-left-list-text"
            :class="titleActive == 2 ? 'active' : ''"
            @click="chooseActive(2)"
          >
            购车服务章程
          </div>
        </div>
        <div class="content-left-list">
          <div class="content-left-list-title">
            延长保修服务
            <img class="flag" src="" alt="" />
          </div>
          <div
            class="content-left-list-text"
            :class="titleActive == 3 ? 'active' : ''"
            @click="chooseActive(3)"
          >
            1年或2万公里，全车无上限保修
          </div>
          <div
            class="content-left-list-text"
            :class="titleActive == 4 ? 'active' : ''"
            @click="chooseActive(4)"
          >
            2年或5万公里，全车无上限保修
          </div>
          <div
            class="content-left-list-text"
            :class="titleActive == 5 ? 'active' : ''"
            @click="chooseActive(5)"
          >
            8年或18万公里 ，发动机、变速箱系统无上限保修
          </div>
        </div>
      </div>
      <div class="content-right" ref="content" v-html="active"></div>
    </div>
  </div>
</template>

<script>
import { yuexinService } from "@/api/index.js";
export default {
  data() {
    return {
      servers: {},
      active: "",
      titleActive: 1,
    };
  },
  mounted() {
    this.yuexinService();
  },
  methods: {
    // 提交
    yuexinService() {
      yuexinService({}).then((res) => {
        if (res.code == 0) {
          this.servers = res.data;
          this.active = this.servers.server1;
        }
      });
    },
    chooseActive(type) {
      this.$refs.content.scroll({top: 0, behavior: "smooth"})
      this.titleActive = type;
      if (type == 1) {
        this.active = this.servers.server1;
      } else if (type == 2) {
        this.active = this.servers.server2;
      } else if (type == 3) {
        this.active = this.servers.server3;
      } else if (type == 4) {
        this.active = this.servers.server4;
      } else if (type == 5) {
        this.active = this.servers.server5;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.serviceDetail {
  width: 100%;
  background-color: #fff;
  min-width: 1200px;

  .content {
    width: 1200px;
    margin: 100px auto;
    display: flex;
    .content-left {
      width: 300px;
      height: 700px;
      border-right: 2px solid #e5e5e5;
      padding-right: 40px;
      .content-left-list {
        .content-left-list-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 24px;
          font-weight: 550;
        }
        .content-left-list-text {
          font-size: 18px;
          color: rgba(99, 99, 99, 0.6);
          line-height: 20px;
          padding-top: 16px;
          cursor: pointer;
          &:hover {
            font-weight: 550;
          }
        }
        .active {
          font-weight: 550;
          color: #000;
        }
        &:nth-child(2) {
          margin-top: 50px;
        }
      }
    }
    .content-right {
      width: 900px;
      padding:0 50px;
      height: 700px;
      overflow-y: auto;

      
    }
  }
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
      ::-webkit-scrollbar {
        width: 3px;
        height: 16px;
        background-color: #f5f5f5;
      }

      /*定义滚动条轨道 内阴影+圆角*/
      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      /*定义滑块 内阴影+圆角*/
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #555;
      }
}
</style>
